<template>
  <div class="box">
    <div class="info">
      <div class="img">
        <img src="../../../assets/image/zhibaiTitle.png" alt="" />
        <div class="teach">授权给</div>
        <div class="company">当前系统</div>
        <ul>
          <li>
            <div class="title">会员权益</div>
            <div class="detail">永久尊享</div>
          </li>
          <li>
            <div class="title">授权域名</div>
            <div class="detail">当前域名</div>
          </li>

          <li>
            <div class="title">授权时间</div>
            <div class="detail">永久</div>
          </li>
          <li>
            <div class="title">服务期限至</div>
            <div class="detail">永久</div>
          </li>
          <li>
            <div class="title">授权状态</div>
            <div class="detail normal">正常</div>
          </li>
        </ul>
      </div>
      <!-- <div class="button">下载授权</div> -->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f4f6f8;
  .info {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    .img {
      width: 549px;
      height: 800px;
      margin-top: 40px;
      border: 5px solid #75748b;
      border-radius: 10px;
      background-size: cover;
      display: flex;
      align-items: center;
      flex-direction: column;
      > img {
        width: 250px;
        height: 100px;
        margin-top: 153px;
      }
      .teach {
        font-size: 16px;
        color: #927345;
        font-weight: 600;
        margin-top: 34px;
      }
      .company {
        font-size: 20px;
        font-weight: bold;
        color: #6c5022;
        margin-top: 18px;
      }
      > ul {
        width: 70%;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        li {
          height: 53px;
          line-height: 53px;
          display: flex;
          list-style: none;
          .title {
            width: 110px;
            color: #927345;
            font-weight: bold;
            padding-bottom: 30px;
          }
          .detail {
            width: 286px;
            border-bottom: 1px solid #f4ebca;
            color: #6c5022;
          }
          .normal {
            color: #19be6b;
          }
        }
      }
    }
    .button {
      width: 88px;
      height: 32px;
      color: #db911f;
      border: 1px solid #f4ebca;
      margin: 46px auto 71px;
      border-radius: 2px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
